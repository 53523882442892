import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { IconButton, Tooltip } from '@mui/material/'
import Layout from '../components/layout'
import SEO from '../components/seo'
import FeatureGrid from '../components/featureGrid/FeatureGrid'
import SocialLink from '../components/socialLink/socialLink'
import { Blog } from '../utils/navLinks'
import Link from '../components/link'
import { Link as ScrollLink } from 'react-scroll'
import BlogGrid from '../components/blog/BlogGrid'
import Logo from '../assets/svg/logo.svg'
import ContactForm from '../components/ContactForm'
import Arrow from '../assets/svg/arrow.svg'

function IndexPage() {
    const { t } = useTranslation()

    return (
        <Layout>
            <SEO title="homepage:title" />
            <div className="welcomeHeader">
                <StaticImage
                    alt="Mountain Central"
                    className="backgroundImage"
                    src="../assets/images/homeHeader.png"
                />

                <div className="logoFull">
                    {/* <Logo className="homeLogo" /> */}
                    <h1 className="homeTitle">Hi, I'm Szymon</h1>
                </div>
                <FeatureGrid />

                <SocialLink />
                <div>
                    <ScrollLink to="about" spy={true} smooth={true}>
                        <Tooltip
                            title="Learn More"
                            aria-label="github"
                            classes={{ popper: 'navPopper', tooltip: 'navTooltip' }}
                        >
                            <IconButton
                                aria-label="Theme Toggle"
                                classes={{ root: 'iconArrow' }}
                                color="inherit"
                                size="large"
                            >
                                <Arrow className="iconArrow" />
                            </IconButton>
                        </Tooltip>
                    </ScrollLink>
                </div>
            </div>

            <div id="about" className="aboutPreview">
                <h2>{t('about:title')}</h2>
                <h4>{t('about:description')}</h4>
                <p>{t('about:firstParagraph')}</p>
                <p>{t('about:secondParagraph')}</p>
                <p>{t('about:thirdParagraph')}</p>
                <p>{t('about:fourthParagraph')}</p>
            </div>

            <div className="blogPreview">
                <h2>{t('blog:title')}</h2>
                <BlogGrid preview />
                <Button to={Blog.path} component={Link} className="seeAllButton">
                    {t('blog:seeAll')}
                </Button>
            </div>
            <div className="contactPreview">
                <div className="row">
                    <div className="column">
                        <h2>{t('contact:title')}</h2>
                        <p>{t('contact:description')}</p>
                        <ContactForm />
                    </div>
                    <div className="column columnhigh">
                        <h3>{t('contact:platforms')}</h3>
                        <ul>
                            <li>
                                <a href="https://www.linkedin.com/in/spankowski/">Linkedin</a>
                            </li>
                            <li>
                                <a href="https://github.com/spankowski">Github</a>
                            </li>
                            <li>
                                <a href="https://www.goodreads.com/spankowski ">GoodReads</a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/spankowski.dev/">Instagram</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default IndexPage
