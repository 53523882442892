import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, Tooltip } from '@mui/material/'
import GitHub from '../../assets/svg/socialIcons/iconmonstr-github-4.svg'
import Linkedin from '../../assets/svg/socialIcons/iconmonstr-linkedin-3.svg'
import IG from '../../assets/svg/socialIcons/iconmonstr-instagram-6.svg'
import Mail from '../../assets/svg/socialIcons/iconmonstr-email-2.svg'

// const MyComponent = Components[Mail + "Component"];


const SocialCard = ({ icon, title, description, url }) => (
    <div className={title}>
                <Tooltip
                    title={title}
                    aria-label="github"
                    classes={{ popper: 'navPopper', tooltip: 'navTooltip' }}
                >
                    <IconButton
                        aria-label="Theme Toggle"
                        classes={{ root: 'navIconButton' }}
                        color="inherit"
                        onClick={() => window.open(url,"_blank")}
                        size="large"
                    >
                        {icon}
                    </IconButton>
                </Tooltip>
                <a>{title}</a> 
            </div>
            
)

SocialCard.propTypes = {
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
}

const SocialLink = () => {
    const socialLinkArray = [
        { icon: <GitHub className="gitLogo" />, title: 'Github', description: 'My Projects', url: 'https://github.com/spankowski' },
        { icon: <Linkedin className="gitLogo"/>, title: 'Linkedin', description: 'My Projects', url: 'https://www.linkedin.com/in/spankowski' },
        { icon: <IG className="gitLogo"/>, title: 'Instagram', description: 'My Projects', url: 'https://www.instagram.com/spankowski.dev/' },
        { icon: <Mail className="gitLogo"/>, title: 'Mail', description: 'My Projects', url: 'https://mailhide.io/e/isxIm0Ik' },
    ]

    return (
        <div className="container">
            {socialLinkArray.map(feature => (
                    <SocialCard
                        key={feature.title}
                        icon={feature.icon}
                        title={feature.title}
                        description={feature.description}
                        url={feature.url}
                    />
                ))}
            
            
        </div>
    )
}
export default SocialLink
